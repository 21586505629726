.App {
  text-align: center;
}

/* CSS */
.button {
  background-color: grey;
  border-radius: 4px;
  border: 0;
  /*box-shadow: rgba(1,60,136,.5) 0 -1px 3px 0 inset,rgba(0,44,97,.1) 0 3px 6px 0;*/
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: "Space Grotesk";
  font-size: 18px;
  font-weight: 700;
  line-height: 12px;
  padding: 16px 20px;
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  transition: all .2s cubic-bezier(.22, .61, .36, 1);
}

.button:hover {
  background-color: black;
  transform: translateY(-2px);
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 5%;
  margin-right: 5%;

  @media screen and (max-width: 800px) {
    margin-left: 1EM;
    margin-right: 1EM;
  }
}

.fourbases-logo {
  position: absolute;
  height: 150px;
  top: -19px;
  left: -14px;
}

.clear-both {
  clear: both;
}

/*@media (min-width: 768px) {*/
/*  button {*/
/*    padding: 16px 44px;*/
/*    min-width: 150px;*/
/*  }*/
/*}*/
