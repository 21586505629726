.team-card {
    padding-top:1.5EM;
    padding-left:2EM;
    height: 300px;
}

.team-card-frame {
    position: relative;
    height: 150px;
    width: 100px;
    border: 7.5px solid var(--primary);
    float: left;
    margin-bottom: 1EM;

    &:before, &:after {
        content: '';
    }

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 8;

        border-top: 22.5px solid var(--primary);
        border-right: 22.5px solid transparent;
    }

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        border-top: 23.5px solid rgb(0, 0, 0);
        border-right: 23.5px solid transparent;
    }

    .team-image {
        position: absolute;
        background: white;
        top: 0;
        left: 0;
        z-index: 2;
        height: 146px;
        width: 96px;
        border: 2px rgb(0, 0, 0) solid;

    }

    .team-name {
        position: absolute;
        bottom: -10px;
        right: 0;
        width: 66%;
        text-align: center;
        font-size: 100%;
        height:30px;
        background: var(--secondary);
        border: 2px rgb(0, 0, 0) solid;
        z-index: 10;

        &:before, &:after {
            content: '';
        }

        &:before {
            position: absolute;
            bottom: 32px;
            right: -2px;
            z-index: 18;
            border-bottom: 15px solid var(--primary);
            border-left: 15px solid transparent;
        }

        &:after {
            position: absolute;
            bottom: 32px;
            right: -2px;
            z-index: 15;
            border-bottom: 17px solid rgb(0, 0, 0);
            border-left: 17px solid transparent;
        }

        .team-name-container {
            position: relative;
            top: 20%;
        }
    }
}

.team-stats {
    width: 100%;
}