.search-card {
    display: inline-block;

    .search-card-image {
        width: 4EM;
        border: 0.25EM solid #555;
        float: left;
    }

    .player-info {
        background-color: white;
        opacity: 1;
        width: 25EM;
        float: left;
        border: 0.25EM solid #555;
        height: 6EM;

        .player-name, .player-team, .player-position {
            padding-top: 0.5EM;
            padding-left: 0.5EM;
            float: left !important;
        }

        .player-avg, .player-hits, .player-home-runs {
            padding-top: 0.5EM;
            padding-right: 0.5EM;
            float: right !important;
        }
    }

    :hover {
        background-color: #45818c;
    }
}

