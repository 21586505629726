.base-stats-frame {
    height: 175px;
    width: 100%;
    border: 15px solid var(--primary);
    position: relative;

    .base-stats-portrait {
        position: relative;
        height: 171px;
        float: right;
        right: 117px;
    }

    .base-stats-section {
        width: calc(100% - 109px);
    }

    &:before, &:after {
        content: '';
    }

    &:before {
        position: absolute;
        top: 0;
        z-index: 8;

        border-top: 45px solid var(--primary);
    }

    &:after {
        position: absolute;
        top: 0;
        z-index: 5;
        border-top: 47px solid rgb(0, 0, 0);
    }

    .team-image {
        position: absolute;
        z-index: 15;
        top: 0px;
        height: 2.5EM;
    }

    .player-image {
        position: absolute;
        background-color:white;
        top: 0;
        z-index: 2;
        height: 100%;
        border: 2px rgb(0, 0, 0) solid;
    }

    .player-name {
        width: 99.25%;
        left: 20px;
        text-align: center;
        height: 15px;
        background: var(--secondary);
        border: 2px rgb(0, 0, 0) solid;
        z-index: 10;

        .player-name-container {
            position: relative;
            bottom: 2.5px;
            margin-left: 15px;
            margin-right: 15px;
            font-size: 10px;
        }
    }

    .stats-table {
        display: table;
        border: 1px solid #ccc;
        border-spacing: 0;
        width: 100%;

        /*@media screen and (max-width: 1600px) {*/
        /*    width: 93%;*/
        /*}*/

        /*@media screen and (max-width: 1300px) {*/
        /*    width: 91%;*/
        /*}*/

        /*@media screen and (max-width: 1100px) {*/
        /*    width: 89%;*/
        /*}*/

        /*@media screen and (max-width: 1000px) {*/
        /*    width: 87%;*/
        /*}*/
        .hide-stats {
            @media screen and (max-width: 800px) {
                display: none;
            }
        }
    }
}