.stat-chart, comparison-chart {
    padding-bottom: 1EM;
}

.player-stat-chart, .comparison-chart {
    height: 300px;
    width: calc(50% - 30px);
    border: 15px solid var(--primary);

    @media screen and (max-width: 800px) {
        width: calc(100% - 30px);
    }
}