.search-bar {
    display: inline-block;
}

.player-search {
    display: block;
    position: relative;
    padding-top: 1EM;
    border-bottom: 2px solid;
    height: 4EM;

    .player-search-input {
        line-height: 1.5EM;
        padding: 6px;
        width: 35EM;
        margin-left: auto;
        margin-right: auto;

        @media screen and (max-width: 800px) {
            width: 25EM;
        }

        @media screen and (max-width: 650px) {
            width: 20EM;
        }
    }
}

.clear-data {
    margin-left: 10px;
    height: 40px;
    padding-top: 14px;
    top: 2px;
    @media screen and (max-width: 800px) {
        padding: 16px 10px;
    }
}

.nav-link {
    float: right;
    margin-right: 8px;
    margin-top: 8px;
    background: none!important;
    border: none;
    padding: 0!important;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}
