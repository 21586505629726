.search-card {
    display: inline-block;

    .search-card-image {
        height: 6EM;
        border: 0.25EM solid #555;
        float: left;
        background-color: #555;
    }

    .team-info {
        background-color: white;
        opacity: 1;
        width: 25EM;
        float: left;
        border: 0.25EM solid #555;
        height: 6EM;

        .team-name {
            padding-top: 0.5EM;
            padding-left: 0.5EM;
            text-aling: center;
        }
    }

    :hover {
        background-color: #45818c;
    }
}

